import {
    Typography,
    Box,
    ListItem,
    IconButton,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    ListItemSecondaryAction,
    List,
    TypographyProps,
    Theme,
    SxProps,
    Button,
} from "@mui/material";
import React, { useMemo, useCallback, useEffect, useState, PropsWithChildren } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";


export interface LightBoxProps extends PropsWithChildren {
    small: React.ReactNode;
    sx?: SxProps<Theme>;
    icon?: JSX.Element;
    buttonProps?: SxProps<Theme>
}

export const LightBox = ({
    small,
    icon,
    sx,
    children
}: LightBoxProps) => {

    const handleSelect = useCallback((id: string) => {
        //navigate(showPath(RESOURCE.organisation, id));
        setOpen(false);
    }, []);

    const [open, setOpen] = useState(false);
    const handleOpen = useCallback(() => {
        setOpen(true);
    }, [setOpen]);
    const handleClose = useCallback(() => {
        setOpen(false);
    }, [setOpen]);
    const _icon = useMemo(() => icon || <ExpandMoreIcon />, [icon]);

    return (
        <Box
            padding="0"
            sx={{
                boxSizing: "border-box",
                ...sx,
            }}
        >
            <Box onClick={handleOpen} sx={{
                cursor: 'pointer', ":hover": {
                    "::after": {
                        background: "rbga(0,0,0,0.3)"
                    }
                }
            }}>
                {small}
            </Box>

            {open ? (
                <Box
                    sx={{
                        zIndex: 5000,
                        position: "fixed",
                        top: "0",
                        left: 0,
                        display: "flex",
                        alignContent: "center",
                        justifyContent: "center",
                        alignItems: "center",
                        justifyItems: "center",
                        width: "100%",
                        height: "100%",
                        background: "rgba(0,0,0,0.3)"
                    }}
                    onClick={handleClose}

                >
                    <Box
                        sx={{
                            background: "#FFF",
                            padding: "10px",
                            boxShadow: 3,
                            boxSizing: "border-box",
                            maxWidth: "100%",
                            maxHeight: "100%"
                        }}
                    >
                        {children}
                    </Box>
                </Box>
            ) : null}

        </Box>
    );
};
